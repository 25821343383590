import { Identity } from '@app/shared/models/identity';
import { Plan } from '@app/shared/models/plan';
import { Role } from '@app/shared/models/role';
import { Team } from '@app/shared/models/team';
import { createAction, props } from '@ngrx/store';
import { CreateTokenOutput } from '@smash-sdk/directory/11-2023/types/CreateToken/CreateToken';
import { GetSubscriptionOutput } from '@smash-sdk/billing/01-2024/types/GetSubscription/GetSubscription';
import { ListSubscriptionLimitsOutput } from '@smash-sdk/billing/07-2021';

// Select Identity
export const selectActiveIdentity = createAction(
  '[Account] Select Active Identity',
  props<{ identity: Identity }>()
);

// Select Active Team
export const computeActiveTeam = createAction(
  '[Account] Compute Active Team',
  props<{ teamId: string }>()
);

export const selectActiveTeam = createAction(
  '[Account] Select Active Team',
  props<{ team: Team }>()
);

// update Active Team
export const updateActiveTeam = createAction(
  '[Account] Update Active Team',
  props<{ team: Team }>()
);

// Billing informations
export const loadBillingInformations = createAction(
  '[Account] Load Billing Informations',
);

export const loadBillingInformationsSuccess = createAction(
  '[Account] Load Billing Informations Success',
  props<{ billingInformations: any }>()
);

export const loadBillingInformationsFailure = createAction(
  '[Account] Load Billing Informations Failure',
  props<{ error: any }>()
);

// Subscription
export const loadSubscription = createAction(
  '[Account] Load Subscription',
);

export const loadSubscriptionSuccess = createAction(
  '[Account] Load Subscription Success',
  props<{ subscription: GetSubscriptionOutput['subscription'], limits?: ListSubscriptionLimitsOutput['limits'] }>()
);

export const loadSubscriptionFailure = createAction(
  '[Account] Load Subscription Failure',
  props<{ error: any }>()
);

export const loadSubscriptionWithPlan = createAction(
  '[Account] Load Subscription With Plan',
);

export const loadSubscriptionWithPlanSuccess = createAction(
  '[Account] Load Subscription With Plan Success',
  props<{ subscription: GetSubscriptionOutput['subscription'], limits?: ListSubscriptionLimitsOutput['limits'] }>()
);

export const loadSubscriptionWithPlanFailure = createAction(
  '[Account] Load Subscription With Plan Failure',
  props<{ error: any }>()
);

// Plan
export const loadPlan = createAction(
  '[Account] Load Plan',
  props<{ subscription: any }>()
);

export const loadPlanSuccess = createAction(
  '[Account] Load Plan Success',
  props<{ plan: Plan }>()
);

export const loadPlanFailure = createAction(
  '[Account] Load Plan Failure',
  props<{ error: any }>()
);

// Anonymous Identity
export const createUserAnonymous = createAction(
  '[Account] Create user anonymous',
);

export const createUserAnonymousSuccess = createAction(
  '[Account] Create user anonymous success',
  props<{ identity: Identity }>()
);

export const createUserAnonymousFailure = createAction(
  '[Account] Create user anonymous failure',
  props<{ error: any }>()
);

// Refresh Token
export const refreshToken = createAction(
  '[Account] Refresh token',
);

export const refreshTokenSuccess = createAction(
  '[Account] Refresh token success',
  props<{ identity: Identity }>()
);

export const refreshTokenFailure = createAction(
  '[Account] Refresh token failure',
  props<{ error: any }>()
);

export const loadPlanGroupFromSubscription = createAction(
  '[Account] Load Plan Group From Subscription',
);

export const loadPlanGroupFromSubscriptionSuccess = createAction(
  '[Account] Load Plan Group From Subscription Success',
  props<{ planGroup: any[] }>()
);

export const loadPlanGroupFromSubscriptionFailure = createAction(
  '[Account] Load Plan Group From Subscription Failure',
  props<{ error: any }>()
);

export const signOut = createAction(
  '[Account] Sign Out',
);

export const signOutSuccess = createAction(
  '[Account] Sign Out Success',
);

export const signInAgain = createAction(
  '[Account] Sign In Again',
);

export const signInAgainSuccess = createAction(
  '[Account] Sign In Again Success',
);

export const getProfileInformation = createAction(
  '[Account] Get Profile Information',
);

export const getProfileInformationSuccess = createAction(
  '[Account] Get Profile Information Success',
  props<{ firstName?: string, lastName?: string }>()
);

export const getProfileInformationFailure = createAction(
  '[Account] Get Profile Information Failure',
  props<{ error: any }>()
);


export const UpdateActiveIdentity = createAction(
  '[Account] Update ActiveIdentity',
  props<{ data: any }>()
);

export const UpdateActiveIdentitySuccess = createAction(
  '[Account] Update ActiveIdentity Success',
  props<{ identity: any }>()
);

export const UpdateActiveIdentityFailure = createAction(
  '[Account] Update ActiveIdentity Failure',
  props<{ error: any }>()
);

export const UpdateUsername = createAction(
  '[Account] Update Username',
  props<{ data: any }>()
);

export const UpdateUsernameSuccess = createAction(
  '[Account] Update Username Success',
  props<{ identity: any }>()
);

export const UpdateUsernameFailure = createAction(
  '[Account] Update Username Failure',
  props<{ error: any }>()
);

export const SetupSsoSession = createAction(
  '[Account] Setup Sso Session',
  props<{ informations: any }>()
);

export const SetupSsoSessionFailure = createAction(
  '[Account] Setup Sso Session Failure',
  props<{ error: any }>()
);

export const SetupSsoSessionSuccess = createAction(
  '[Account] Setup Sso Session Success'
);

export const LoadUserRoles = createAction(
  '[Account] Load User Roles',
);

export const LoadUserRolesFailure = createAction(
  '[Account] Load User Roles Failure',
  props<{ error: any }>()
);

export const LoadUserRolesSuccess = createAction(
  '[Account] Load User Roles Success',
  props<{ roles: Role[], isAdministrator: boolean }>()
);

export const LoadUserRolesAndTeams = createAction(
  '[Account] Load User And Teams Roles',
);

export const LoadUserRolesAndTeamsFailure = createAction(
  '[Account] Load User And Teams Roles Failure',
  props<{ error: any }>()
);

export const LoadUserRolesAndTeamsSuccess = createAction(
  '[Account] Load User And Teams Roles Success',
  props<{ roles: Role[], teams: Team[], isAdministrator: boolean }>()
);

export const UpdatePassword = createAction(
  '[Account] Update Password',
  props<{ currentPassword: string, newPassword: string }>()
);

export const UpdatePasswordSuccess = createAction(
  '[Account] Update Password Success',
  props<{ identity: CreateTokenOutput['identity'] }>()
);

export const UpdatePasswordFailure = createAction(
  '[Account] Update Password Failure',
  props<{ error: any }>()
);

export const SetUserContacts = createAction('[Account] Set User Contacts', props<{ userContacts: any[] }>());
export const updateUserContacts = createAction('[Account] Update User Contacts', props<{ userContacts: any[] }>());

export const LoadUserTeams = createAction(
  '[Account] Load User Teams',
);

export const LoadUserTeamsFailure = createAction(
  '[Account] Load User Teams Failure',
  props<{ error: any }>()
);

export const LoadUserTeamsSuccess = createAction(
  '[Account] Load User Teams Success',
  props<{ teams: Team[] }>()
);
