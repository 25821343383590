import { InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { splitUrl } from '@app/shared/helpers/urlHelper';
import * as fromRouter from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromCustomization from '../client/account/team/customization/reducers/customization.reducer';
import * as fromAccount from '../core/reducers/account.reducer';
import * as fromTheme from '../core/reducers/theme.reducer';
import * as fromDomain from '../core/reducers/domain.reducer';
import * as fromEnvironment from '../core/reducers/environment.reducer';
import * as fromPayment from '../core/reducers/payment.reducer';
import * as fromScript from '../core/reducers/script.reducer'; // not used
import * as fromCaptcha from '../core/reducers/captcha.reducer';

export interface State {
  [fromAccount.userFeatureKey]: fromAccount.State;
  [fromEnvironment.environmentFeatureKey]: fromEnvironment.State;
  [fromScript.scriptFeatureKey]: fromScript.State; // not used
  [fromDomain.domainFeatureKey]: fromDomain.State;
  [fromCustomization.customizationFeatureKey]: fromCustomization.State;
  [fromPayment.paymentFeatureKey]: fromPayment.State;
  [fromTheme.themeFeatureKey]: fromTheme.State;
  [fromCaptcha.captchaFeatureKey]: fromCaptcha.State;
  router: fromRouter.RouterReducerState<any>;
}


export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    router: fromRouter.routerReducer,
    [fromAccount.userFeatureKey]: fromAccount.reducer,
    [fromEnvironment.environmentFeatureKey]: fromEnvironment.reducer,
    [fromScript.scriptFeatureKey]: fromScript.reducer, // not used
    [fromDomain.domainFeatureKey]: fromDomain.reducer,
    [fromCustomization.customizationFeatureKey]: fromCustomization.reducer,
    [fromPayment.paymentFeatureKey]: fromPayment.reducer,
    [fromTheme.themeFeatureKey]: fromTheme.reducer,
    [fromCaptcha.captchaFeatureKey]: fromCaptcha.reducer,
  }),
});


export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    return result;
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];

// fromCustomization
export const getCustomizationState = createFeatureSelector<State>('userCustomization');

// fromAccount
export const getAccountState = createFeatureSelector<fromAccount.State>('account');

// Router State
export const selectRouter = createFeatureSelector<fromRouter.RouterReducerState<any>>('router');

export const {
  selectCurrentRoute,   // select the current route
  selectQueryParams: selectQueryParamsNgRx,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

export const getRoute = createSelector(
  selectUrl,
  selectRouteData,
  selectCurrentRoute,
  (currentUrl, data, currentRoute) => {
    return {
      currentUrl,
      data,
      currentRoute,
      splittedRoute: splitUrl(currentUrl)
    };
  });

const getRouteParams = (route: ActivatedRouteSnapshot) => {
  if (route.children.length === 0) {
    return route.params;
  }

  const combinedChildParams = route.children.reduce(
    (prev, childRoute) => ({ ...prev, ...getRouteParams(childRoute) }),
    {}
  );
  return {
    ...route.params,
    ...combinedChildParams
  };
};

export const selectRouteParams = createSelector(selectRouter, routerState => {
  if (!routerState?.state?.root) {
    return {};
  }

  return getRouteParams(routerState.state.root);
});

export const selectRouteParam = (routeParam: string) => createSelector(selectRouteParams, (routeParams) => {
  return routeParams[routeParam];
});

export const selectQueryParams = createSelector(
  selectQueryParamsNgRx,
  params => params || {}
);

export const getActiveIdentity = createSelector(getAccountState, (state) => state.activeIdentity);
export const getUsernameUpdating = createSelector(getAccountState, (state) => state.usernameUpdating);
export const getUsernameUpdated = createSelector(getAccountState, (state) => state.usernameUpdated);
export const getUsernameUpdateError = createSelector(getAccountState, (state) => state.usernameUpdateError);
export const getPasswordUpdating = createSelector(getAccountState, (state) => state.passwordUpdating);
export const getPasswordUpdated = createSelector(getAccountState, (state) => state.passwordUpdated);
export const getPasswordUpdateError = createSelector(getAccountState, (state) => state.passwordUpdateError);
export const getActiveTeam = createSelector(getAccountState, (state) => state.activeTeam);
export const getToken = createSelector(getAccountState, (state) => state?.activeIdentity?.token?.token);
export const getRefreshToken = createSelector(getAccountState, (state) => (state?.activeIdentity?.refreshToken));
export const getRefreshTokenLoading = createSelector(getAccountState, (state) => (state?.refreshTokenLoading));
export const getRefreshTokenLoaded = createSelector(getAccountState, (state) => (state?.refreshTokenLoaded));
export const getRefreshTokenError = createSelector(getAccountState, (state) => (state?.refreshTokenError));
export const getBillingInformations = createSelector(getAccountState, (state) => state.billingInformations);
export const getContacts = createSelector(getAccountState, (state) => state.userContacts);

export const getBillingInformationsForms = createSelector(getAccountState, (state) => {

  return {
    address: state.billingInformations.address,
    city: state.billingInformations.city,
    company: state.billingInformations.company,
    country: state.billingInformations.country,
    state: state.billingInformations.state,
    firstName: state.billingInformations.firstName,
    lastName: state.billingInformations.lastName,
    zipCode: state.billingInformations.zipCode,
  };
});
export const getSubscription = createSelector(getAccountState, (state) => state.subscription);
export const getSubscriptionOptionsDomainMax = createSelector(getAccountState, (state) => state.subscriptionLimits?.services?.domain?.domain?.max);

// FIX ME when billing is updated
export const getPlanType = createSelector(getAccountState, (state) => {
  if (state.plan) {
    return (state.plan.maxActiveUser > 1 ? 'TEAM' : 'PREMIUM');
  }
  return null;
});

export const getIsTeamPlan = createSelector(getAccountState, (state) => {
  if (state.plan) {
    return state?.plan?.maxActiveUser > 1;
  }
  return false;
});

export const getIsPlanPremium = createSelector(getAccountState, (state) => {
  return state?.plan?.maxActiveUser === 1;
});

export const getPlan = createSelector(getAccountState, (state) => state.plan);
export const getAllBillingInformations = createSelector(getAccountState, (state) => ({ subscription: state.subscription, billingInformations: state.billingInformations }));
export const getBillingInformationsLoaded = createSelector(getAccountState, (state) => state.billingInformationsLoaded);
export const getSubscriptionLoaded = createSelector(getAccountState, (state) => state.subscriptionLoaded);
export const getSubscriptionUpdating = createSelector(getAccountState, (state) => state.subscriptionUpdating);
export const getAllBillingInformationsLoaded = createSelector(getAccountState, (state) => state.subscriptionLoaded && state.billingInformationsLoaded);
export const getHasToken = createSelector(getAccountState, (state) => !!state?.activeIdentity?.username);
export const getActiveIdentityUpdating = createSelector(getAccountState, (state) => state.activeIdentityUpdating);
export const getActiveIdentityUpdated = createSelector(getAccountState, (state) => state.activeIdentityUpdated);
export const getIsAdministrator = createSelector(getAccountState, (state) => (state?.activeIdentity?.isRoot || state?.isAdministrator));
export const getUserRolesAndTeamsLoaded = createSelector(getAccountState, (state) => (state.userRolesAndTeamsLoaded));
export const getUserTeams = createSelector(getAccountState, (state) => state.userTeams);
export const getUserTeamsLoading = createSelector(getAccountState, (state) => state.userTeamsLoading);
export const getUserTeamsLoaded = createSelector(getAccountState, (state) => state.userTeamsLoaded);
export const getUserRoles = createSelector(getAccountState, (state) => state.userRoles);
export const getUserRolesLoading = createSelector(getAccountState, (state) => state.userRolesLoading);
export const getUserRolesLoaded = createSelector(getAccountState, (state) => state.userRolesLoaded);
export const getAccountError = createSelector(getAccountState, (state) => state.errorMessage);

export const getSetupSsoSessionSuccess = createSelector(getAccountState, (state) => state.setupSsoSessionSuccess);

// fromCaptcha
export const getCaptchaState = createFeatureSelector<fromCaptcha.State>('captcha');
export const getCaptchaChallenge = createSelector(getCaptchaState, (state) => state.challenge);


// fromEnvironment
export const getEnvironmentState = createFeatureSelector<fromEnvironment.State>('environment');
export const getCurrentRegion = createSelector(getEnvironmentState, (state) => state.region);

export const selectServices = createSelector(getEnvironmentState, (state) => ({ services: state.services, region: state.region, country: state.country, countryCode: state.countryCode }));
export const selectServicesIam = createSelector(getEnvironmentState, (state) => ({ iam: state.services.find(s => s.name === 'iam' && s.region === state.region), region: state.region }));
export const getCountries = createSelector(getEnvironmentState, (state) => state.countries);
export const getCountriesLoaded = createSelector(getEnvironmentState, (state) => state.countriesLoaded);
export const getCountryCode = createSelector(getEnvironmentState, (state) => state.countryCode);
export const getDevice = createSelector(getEnvironmentState, (state) => state.device);
export const getSelectedLanguage = createSelector(getEnvironmentState, (state) => state.selectedLanguage);
export const getSupportedLanguages = createSelector(getEnvironmentState, (state) => state.supportedLanguages);
export const getUpdateRunning = createSelector(getEnvironmentState, (state) => state.updateRunning);
export const getUpdateRolesAndPoliciesSuccess = createSelector(getEnvironmentState, (state) => state.updateRolesAndPoliciesSuccess);
export const getPendingUpdates = createSelector(getEnvironmentState, (state) => state.pendingUpdates);
export const getCGUConsent = createSelector(getEnvironmentState, (state) => state.cgu);
export const getStripeLoaded = createSelector(getEnvironmentState, (state) => state.stripeLoaded);
export const getCookiesConsentConfiguration = createSelector(getEnvironmentState, (state) => state.cookiesConsentConfiguration);
export const getIsCookiesConsentSetByUser = createSelector(getEnvironmentState, (state) => state.cookiesConsentSetByUser);
export const getLoadServicesError = createSelector(getEnvironmentState, (state) => state.loadServicesError);
export const getPlayersState = createSelector(getEnvironmentState, state => state.players);

export const getPlayers = createSelector(
  getPlayersState,
  fromEnvironment.getPlayers
);

export const getPlayer = (id: string) => createSelector(
  getPlayers,
  (players) => players.find(player => player.id === id)
);

export const getOnBoardingState = createSelector(getEnvironmentState, state => state.onBoarding);

// fromScript
export const getScriptState = createFeatureSelector<fromScript.State>('script');
export const getLoadedScripts = createSelector(getScriptState, (state) => state.scripts);

// fromDomain
export const getDomainState = createFeatureSelector<fromDomain.State>('domain');
export const getIsMainDomain = createSelector(getDomainState, (state) => state.currentDomainLoaded && state.currentDomain?.domain && !state.currentDomain?.team);
export const getMainDomain = createSelector(getDomainState, (state) => state.mainDomain);
export const getCurrentDomain = createSelector(getDomainState, (state) => state.currentDomain);
export const getCurrentDomainLoaded = createSelector(getDomainState, (state) => state.currentDomainLoaded);
export const getCurrentDomainLoading = createSelector(getDomainState, (state) => state.currentDomainLoading);
export const getCurrentDomainProviders = createSelector(getDomainState, (state) => state.currentDomainProviders);
export const getDomainToCreate = createSelector(getDomainState, (state) => state.domainToCreate);
export const getDomainCreating = createSelector(getDomainState, (state) => state.domainCreating);
export const getDomainCreatingError = createSelector(getDomainState, (state) => state.domainCreatingError);
export const getIsFreemium = createSelector(getDomainState, (state) => !state.currentDomain?.team);
export const getIsPremium = createSelector(getDomainState, (state) => !!state.currentDomain?.team);
export const getLoadDomainError = createSelector(getDomainState, (state) => state.loadDomainError);

// Is uploader configuration customize
export const canConfigSmash = createSelector(
  getDomainState,
  fromDomain.canConfigSmash
);

// Default uploader configuration or custom uploader configuration
export const getDomainTransferSettings = createSelector(
  getDomainState,
  fromDomain.getDomainTransferSettings
);

export const getDropboxTransferSettings = createSelector(
  getDomainState,
  fromDomain.getDropboxTransferSettings
);

// fromPayment
export const getPaymentState = createFeatureSelector<fromPayment.State>('payment');

export const getPaymentEntitiesState = createSelector(getPaymentState, (state) => state);
export const getPaymentProcessing = createSelector(getPaymentEntitiesState, (state) => state.paymentProcessing);
export const getPaymentSucceed = createSelector(getPaymentEntitiesState, (state) => state.paymentSucceed);
export const getPaymentFailed = createSelector(getPaymentEntitiesState, (state) => state.paymentFailed);
export const getPaymentError = createSelector(getPaymentEntitiesState, (state) => state.paymentError);
export const getPaypalAgreement = createSelector(getPaymentEntitiesState, (state) => state.paypal);
export const getResetPayment = createSelector(getPaymentEntitiesState, (state) => state.resetPayment);
export const getPaymentType = createSelector(getPaymentEntitiesState, (state) => state.paymentType);
export const getCardToken = createSelector(getPaymentEntitiesState, (state) => state.cardToken);

// fromTheme
export const getThemeState = createFeatureSelector<State, fromTheme.State>('theme');
export const getTheme = createSelector(getThemeState, (state) => state.theme);
export const getThemeLoading = createSelector(getThemeState, (state) => state.themeLoading);
export const getThemeLoaded = createSelector(getThemeState, (state) => state.themeLoaded);
